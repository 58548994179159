<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
        <el-row>
            <el-col>
                <el-card shadow="hover" style="width: 100%; height: 430px;">
                    <div ref="chart" style="width: 100%; height: 430px;">欢迎来到网推人，来了就是义父，就是人上人！</div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>


<style scoped>
.card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 700px;
  height: 50px; /* 设置卡片高度，根据需要调整 */
}

.content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.descriptions {
  width: 100%;
}

.descriptions-item {
  display: flex;
  align-items: center;
}

</style>
