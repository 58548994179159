<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div>
        <el-row>
            <el-col>
                <div>
                    <el-breadcrumb :separator-icon="ArrowRight">
                        <el-breadcrumb-item :to="{ path: '/slysite' }">站点管理</el-breadcrumb-item>
                        <el-breadcrumb-item>站点配置</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </el-col>
        </el-row>
        
        <el-row>
            <el-col :span="20">
                <div>
                    <el-button type="primary" :icon="Plus" @click="dialogAddVisible = true" style="margin-right: 100px;">添加</el-button>
                    <el-dialog
                        v-model="dialogAddVisible"
                        title="添加"
                        :close-on-click-modal="false"
                        width="70%"
                        style="padding-right: 50px; padding-bottom: 30px;"
                    >
                        <el-form
                            :model="addForm"
                            label-position="right"
                            label-width="110"
                        >
                            <el-form-item label="网站名" required>
                                <el-input v-model="addForm.host"></el-input>
                            </el-form-item>
                            <el-form-item label="网站别名" required>
                                <el-input v-model="addForm.alias"></el-input>
                            </el-form-item>
                            <el-form-item label="用户ID">
                                <el-input-number v-model="addForm.uid" :min="0"></el-input-number>
                            </el-form-item>
                             <el-form-item label="统计链接" required>
                                <el-input v-model="addForm.statis_url"></el-input>
                            </el-form-item>
                             <el-form-item label="备注">
                                <el-input v-model="addForm.remark"></el-input>
                            </el-form-item>
                            <el-form-item label="统计代码" >
                                <el-input type="textarea" :autosize="{minRows: 6}" v-model="addForm.statis_code"></el-input>
                            </el-form-item>
                        </el-form>

                        <template #footer>
                            <div class="dialog-footer">
                                <el-button @click="addCancel">取消</el-button>
                                <el-button type="primary" @click="addSubmit">保存</el-button>
                            </div>
                        </template>
                    </el-dialog>

                    <el-input
                        v-model="alias"
                        style="width: 400px; margin-right: 12px;"
                        placeholder="网站别名"
                        >
                        <template #append>
                            <el-button :icon="Search" @click="getTableData"/>
                        </template>
                    </el-input>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <div>
                    <el-table
                        v-loading="loading"
                        stripe
                        :data="tableData" 
                        border style="width: 100%"
                    >
                        <el-table-column prop="id" label="ID" width="70" align="center"/>
                        <el-table-column prop="uid" label="用户ID" align="center"/>
                        <el-table-column prop="host" label="网站名" align="center"/>
                        <el-table-column prop="alias" label="网站别名" align="center"/>
                        <el-table-column prop="remark" label="备注" align="center"/>
                        <el-table-column prop="statis_url" label="统计链接" align="center">
                            <template #default="{ row }">
                                    <el-link target="_blank" :href="row.statis_url" type="primary">
                                        v6.51.la/{{row.id}}
                                    </el-link>
                            </template>
                        </el-table-column>
                        <el-table-column prop="create_time" label="创建时间" width="155" align="center"/>
                        <el-table-column prop="update_time" label="编辑时间" width="155" align="center"/>
                        <el-table-column label="操作" align="center" fixed="right" width="200">
                            <template #default="{ row }">
                                <div class="button-row">
                                    <el-button size="small" style="margin-right: 10px;" @click="handleEdit(row)">编辑</el-button>
                                    <router-link target="_blank" :to="'/open/v1/website/detail?id='+row.id+'&key='+row.token" style="margin-right: 10px;">
                                        <el-button type="primary" size="small" >配置</el-button>
                                    </router-link>
                                    <el-button size="small" type="danger" @click="handleDel(row)">删除</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div>
                        <el-dialog
                            v-model="dialogEditVisible"
                            title="修改"
                            :close-on-click-modal="false"
                            width="70%"
                            style="padding-right: 50px; padding-bottom: 30px;"
                        >

                            <el-form
                                :model="editForm"
                                label-position="right"
                                label-width="110"
                            >
                                <el-form-item label="网站名" required>
                                    <el-input v-model="editForm.host" disabled></el-input>
                                </el-form-item>
                                <el-form-item label="网站别名" required>
                                    <el-input v-model="editForm.alias"></el-input>
                                </el-form-item>
                                 <el-form-item label="统计链接" required>
                                    <el-input v-model="editForm.statis_url"></el-input>
                                </el-form-item>
                                 <el-form-item label="备注">
                                    <el-input v-model="editForm.remark"></el-input>
                                </el-form-item>
                                <el-form-item label="统计代码" >
                                    <el-input type="textarea" :autosize="{minRows: 6}" v-model="editForm.statis_code"></el-input>
                                </el-form-item>
                            </el-form>

                            <template #footer>
                                <div class="dialog-footer">
                                    <el-button @click="dialogEditVisible = false">取消</el-button>
                                    <el-button type="primary" @click="editSubmit">保存</el-button>
                                </div>
                            </template>
                        </el-dialog>
                    </div>

                </div>
            </el-col>
        </el-row>

        <el-row>
            <el-col>
                <el-pagination
                    v-model:current-page="currentPage"
                    v-model:page-size="pageSize"
                    :page-sizes="[10, 20, 30, 40, 50]"
                    background="false"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </el-col>
        </el-row>


    </div>
</template>

<script>
import { apis } from '../apis';
import { Plus, Search } from '@element-plus/icons-vue'
import { ElMessageBox } from 'element-plus'

export default {
    data() {
        return {
            Plus,
            Search,
            loading: true,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            intervalId: 0,
            tableData: [],
            dialogAddVisible: false,
            dialogEditVisible: false,
            dialogAccessVisible: false,
            addForm: {},
            editForm: {},
            host: '',
            'alias': '',
            'remark': ''
        };
    },
    mounted() {
        this.init();
        //this.intervalId = setInterval(() => { this.init(); }, 20000);
        document.addEventListener('paste', this.imagesPaste);
    },
    unmounted() {
        // 卸载页面时停止，否则会一直执行
        clearInterval(this.intervalId);
        document.removeEventListener('paste', this.imagesPaste);
    },
    watch: {
        fileList: { 
            handler(newVal, oldVal) {
                this.fileListChange(newVal, oldVal);
            },
            deep: true,
        },
    },
    computed: {},
    methods: {
        dialogAccessClose() {
            this.accessCID = 0;
            this.accessTableData = [];
            this.accessTotal = 0;
            this.accessLoading = true;
        },
        handleAccessSizeChange() {
            this.getAccessTableData();
        },
        handleAccessCurrentChange() {
            this.getAccessTableData();
        },
        handleAccess(row) {
            this.accessCID = row.id;
            this.dialogAccessVisible = true;
            this.getAccessTableData();
        },
        handleDel(row) {
            ElMessageBox.confirm(
                '确定要删除吗？',
                '信息',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
            .then(() => {
                apis.delete(`/api/v2/website/${row.id}`).then(() => {
                    this.getTableData();
                    this.$message.success('完成成功');
                }).catch(() => {});
            }).catch(() => {})
        },
        editSubmit() {
            let data = {
                alias: this.editForm.alias,
                remark: this.editForm.remark,
                statis_url: this.editForm.statis_url,
                statis_code: this.editForm.statis_code,
            };
            apis.put(`/api/v2/website/${this.editForm.id}`, data).then(() => {
                this.getTableData();
                this.$message.success('更新成功');
                this.dialogEditVisible = false
            }).catch(() => {});
        },
        handleEdit(row) {
            this.editForm = row;
            this.dialogEditVisible = true;
        },
        handleSizeChange() {
            this.getTableData();
        },
        handleCurrentChange() {
            this.getTableData();
        },
        addSubmit() {
            let data = this.addForm
            apis.post('/api/v2/website', data).then(() => {
                this.getTableData();
                this.$message.success('添加成功');
                this.addCancel();
            }).catch(() => {});
        },
        addCancel() {
            this.addForm = {};
            this.dialogAddVisible = false;
        },
        init() {
            this.getTableData();
        },
        getTableData() {
            let url = `/api/v2/website?page=${this.currentPage}&perPage=${this.pageSize}`;
            if (this.alias) {
                url += `&alias=${this.alias}`;
            }
            apis.get(url).then(resp => {
                this.tableData = resp.data.rows;
                this.total = resp.data.count;
                this.loading = false;
            }).catch(() => {});
        },
    },
};
</script>

<style>
.el-row {
    margin-bottom: 20px;
}
.el-row:last-child {
    margin-bottom: 0;
}

.ellipsis {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button-row {
  display: flex;
  margin-bottom: 5px; /* 调整行间距 */
}

</style>

