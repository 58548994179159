import {ElNotification as notification} from 'element-plus';

export function fetchJson(url, {method = 'GET', data, params} = {}) {
    let init = {
        method,
        credentials: 'include',
        cache: 'no-cache',
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
    };
    if (data) {
        init.body = JSON.stringify(data);
    }
    if (params) {
        let query = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&')
            .replace(/%20/g, '+');
        if (query) {
            url = `${url}?${query}`;
        }
    }
    return fetch(url, init).then(function (response) {
        if (!response.ok) {
            return new Promise((resolve, reject) => {
                let res = {
                    status: response.status,
                };
                if (response.status >= 500) {
                    response.json().then(data => {
                        res.message = '服务器处理错误:' + data.msg;
                        reject(res);
                    });
                }
                else {
                    response.json().then(data => {
                        res.message = data;
                        reject(res);
                    });
                }
            });
        }
        return response.json();
    });
}


export class Fetch {
    fetch(url, {method = 'GET', data, params} = {}, catchException = true, catchStatusError = true) {
        let promise =  fetchJson(url, {
            method,
            data,
            params,
        });
        if (catchStatusError) {
            promise = promise.then(res => {
                if (res.status !== 0) {
                    return new Promise((resolve, reject) => {
                        reject({
                            message: 'api resp status != 0, msg: ' + res.msg,
                        });
                    });
                }
                return res;
            });
        }
        if (catchException) {
            promise = promise.catch(err => {
                notification({
                    title: '请求异常',
                    message: JSON.stringify(err.message),
                    type: 'error',
                    duration: 30000,
                });
                return new Promise((resolve, reject) => {
                    reject(err);
                });
            });
        }
        return promise;
    }
}


export class Api extends Fetch {
    get(url, params, catchException, catchStatusError) {
        return this.fetch(url, {
            method: 'GET',
            params,
        }, catchException, catchStatusError);
    }
    post(url, data, catchException, catchStatusError) {
        return this.fetch(url, {
            method: 'POST',
            data,
        }, catchException, catchStatusError);
    }
    put(url, data, catchException, catchStatusError) {
        return this.fetch(url, {
            method: 'PUT',
            data,
        }, catchException, catchStatusError);
    }
    delete(url, params, catchException, catchStatusError) {
        return this.fetch(url, {
            method: 'DELETE',
            params,
        }, catchException, catchStatusError);
    }
    patch(url, data, catchException, catchStatusError) {
        return this.fetch(url, {
            method: 'PATCH',
            data,
        }, catchException, catchStatusError);
    }
}

export const apis = new Api();