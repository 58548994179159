<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="menu-container">
        <el-menu
            class="el-menu-vertical-demo"
            :default-active="activePath"
            :default-openeds="defaultOpeneds"
            :router="true"
        >
            <el-sub-menu index="/slysite" v-if="isShowSubMenu('/slysite')">
                <template #title>站点管理</template>
                <el-menu-item index="/slysite/websiteTpl">
                    <template #title>
                        <el-icon><Reading /></el-icon>
                        <span>站点模板</span>
                    </template>
                </el-menu-item>
                <el-menu-item index="/slysite/website">
                    <template #title>
                        <el-icon><Link /></el-icon>
                        <span>站点配置</span>
                    </template>
                </el-menu-item>
                <el-menu-item index="/slysite/websitehtml">
                    <template #title>
                        <el-icon><Link /></el-icon>
                        <span>站点HTML</span>
                    </template>
                </el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="/sly" v-if="isShowSubMenu('/sly')">
                <template #title>网推码</template>
                <el-menu-item index="/sly/info">
                    <template #title>
                        <el-icon><Reading /></el-icon>
                        <span>访问信息</span>
                    </template>
                </el-menu-item>
                <el-menu-item index="/sly/codes">
                    <template #title>
                        <el-icon><Link /></el-icon>
                        <span>主码管理</span>
                    </template>
                </el-menu-item>
                <el-menu-item index="/sly/subcode">
                    <template #title>
                        <el-icon><Paperclip /></el-icon>
                        <span>副码管理</span>
                    </template>
                </el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="/dy" v-if="isShowSubMenu('/dy')">
                <template #title>抖音码</template>
                <el-menu-item index="/dy/info">
                    <template #title>
                        <el-icon><Reading /></el-icon>
                        <span>访问信息</span>
                    </template>
                </el-menu-item>
                <el-menu-item index="/dy/codes">
                    <template #title>
                        <el-icon><Link /></el-icon>
                        <span>跳转码</span>
                    </template>
                </el-menu-item>
                <el-menu-item index="/dy/subcode">
                    <template #title>
                        <el-icon><Paperclip /></el-icon>
                        <span>副码跳转链接</span>
                    </template>
                </el-menu-item>
            </el-sub-menu>
        </el-menu>

        

    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            activePath: this.$route.path,
            defaultOpeneds: ['/sly'],
        };
    },
    watch: {
        $route(to) {
            this.activePath = to.path;
        },
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'getUser']),
        subMenuSet() {
            if (this.getUser.perms === null || !Array.isArray(this.getUser.perms) || this.getUser.perms.length === 0) {
                return new Set();
            }
            return new Set(this.getUser.perms);
        },
    },
    async mounted() {},
    methods: {
        isShowSubMenu(path) {
            if (this.subMenuSet.size == 0) {
                return true;
            }
            
            return this.subMenuSet.has(path);
        },
    },
};
</script>

<style scoped>
.menu-container {
  height: 100vh; /* 设置容器高度为视口高度 */
}
.el-menu-vertical-demo {
  height: 100%; /* 设置菜单高度为容器高度 */
}
</style>
