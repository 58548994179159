<template>
    <div>
       <h1>1233</h1>


    </div>
</template>

<script>
import { apis } from '../apis';
import { Plus, Search } from '@element-plus/icons-vue'
import { ElMessageBox } from 'element-plus'

export default {
    data() {
        return {
            Plus,
            Search,
            loading: true,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            intervalId: 0,
            tableData: [],
            dialogAddVisible: false,
            dialogEditVisible: false,
            dialogAccessVisible: false,
            addForm: {},
            editForm: {},
            remark: '',
        };
    },
    mounted() {
        this.init();
        //this.intervalId = setInterval(() => { this.init(); }, 20000);
        document.addEventListener('paste', this.imagesPaste);
    },
    unmounted() {
        // 卸载页面时停止，否则会一直执行
        clearInterval(this.intervalId);
        document.removeEventListener('paste', this.imagesPaste);
    },
    watch: {
        fileList: { 
            handler(newVal, oldVal) {
                this.fileListChange(newVal, oldVal);
            },
            deep: true,
        },
    },
    computed: {},
    methods: {
        dialogAccessClose() {
            this.accessCID = 0;
            this.accessTableData = [];
            this.accessTotal = 0;
            this.accessLoading = true;
        },
        handleAccessSizeChange() {
            this.getAccessTableData();
        },
        handleAccessCurrentChange() {
            this.getAccessTableData();
        },
        handleAccess(row) {
            this.accessCID = row.id;
            this.dialogAccessVisible = true;
            this.getAccessTableData();
        },
        handleDel(row) {
            ElMessageBox.confirm(
                '确定要删除吗？',
                '信息',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
            .then(() => {
                apis.delete(`/api/v2/websitetpl/${row.id}`).then(() => {
                    this.getTableData();
                    this.$message.success('完成成功');
                }).catch(() => {});
            }).catch(() => {})
        },
        editSubmit() {
            let data = {
                title: this.editForm.title,
                remark: this.editForm.remark,
                content: this.editForm.content,
            };
            apis.put(`/api/v2/websitetpl/${this.editForm.id}`, data).then(() => {
                this.getTableData();
                this.$message.success('更新成功');
                this.dialogEditVisible = false
            }).catch(() => {});
        },
        handleEdit(row) {
            this.editForm = row;
            this.dialogEditVisible = true;
        },
        handleSizeChange() {
            this.getTableData();
        },
        handleCurrentChange() {
            this.getTableData();
        },
        addSubmit() {
            let data = this.addForm
            apis.post('/api/v2/websitetpl', data).then(() => {
                this.getTableData();
                this.$message.success('添加成功');
                this.addCancel();
            }).catch(() => {});
        },
        addCancel() {
            this.addForm = {};
            this.dialogAddVisible = false;
        },
        init() {
            this.getTableData();
        },
        getTableData() {
            let url = `/api/v2/websitetpl?page=${this.currentPage}&perPage=${this.pageSize}`;
            if (this.remark) {
                url += `&remark=${this.remark}`;
            }
            apis.get(url).then(resp => {
                this.tableData = resp.data.rows;
                this.total = resp.data.count;
                this.loading = false;
            }).catch(() => {});
        },
    },
};
</script>

<style>
.el-row {
    margin-bottom: 20px;
}
.el-row:last-child {
    margin-bottom: 0;
}

.ellipsis {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button-row {
  display: flex;
  margin-bottom: 5px; /* 调整行间距 */
}

</style>

