import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';

import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css';

import PlatformHeader from './components/Header.vue';
import PlatformAside from './components/Aside.vue';
import { apis } from './apis';


apis.get('/api/open/v2/user/current').then(resp => {
    if (!resp.data.isLogin) {
        let url = window.location.href;
        console.log(url);
        window.location.href = '/api/open/v2/user/loginpage?url=' + encodeURIComponent(url);
        return;
    }

    store.commit('setUser', resp.data);
    const app = createApp(App);
    app.component('pl-header', PlatformHeader);
    app.component('pl-aside', PlatformAside);
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component)
    }
    app.use(router);
    app.use(store);
    app.use(ElementPlus);
    app.mount('#app');

}).catch(err => {
    console.log(err);
    alert('获取用户失败，请刷新重试');
});
