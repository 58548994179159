<template>
    <div class="common-layout">
        <el-container style="height: 100vh;">
            <el-header style="padding: 0; border-bottom: 2px solid #DCDFE6;">
                <pl-header/>
            </el-header>
            <el-container>
                <el-aside width="200px" shadow="always">
                    <pl-aside :items="routes" style="height: 100%;"/>
                </el-aside>
                <el-main style="padding: 20px; height: 100%;">
                    <router-view class="body"/>
                </el-main>
            </el-container>
    </el-container>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {}
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  height: 100%;
}
</style>
