<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div>
        <el-row>
            <el-col>
                <div>
                    <el-breadcrumb :separator-icon="ArrowRight">
                        <el-breadcrumb-item :to="{ path: '/sly' }">网推码</el-breadcrumb-item>
                        <el-breadcrumb-item>副码管理</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </el-col>
        </el-row>

        <el-row>
            <el-col>
                <div>
                    <el-button type="primary" :icon="Plus" @click="dialogAddVisible = true" style="margin-right: 100px;">添加副码</el-button>
                    
                    <el-dialog
                        v-model="dialogAddVisible"
                        title="添加"
                        :close-on-click-modal="false"
                        width="70%"
                        style="padding-right: 50px; padding-bottom: 30px;"
                    >

                        <el-form
                            :model="addForm"
                            label-position="right"
                            label-width="110"
                        >

                            <el-form-item label="方式">
                                <el-radio-group v-model="addForm.type" class="ml-4">
                                    <el-radio value="1" size="large">上传图片，自动获取图片链接</el-radio>
                                    <el-radio value="2" size="large">手动输入链接</el-radio>
                                </el-radio-group>
                            </el-form-item>

                            <el-form-item
                                v-if="addForm.type == 1"
                                label="图片上传"
                                required
                            >
                                <div>
                                    <el-upload
                                        ref="upload"
                                        action="https://china2.xlzy520.cn/xd/upload"
                                        :file-list="fileList"
                                        list-type="picture-card"
                                        :auto-upload="true"
                                        :on-success="imageUploadSuccess"
                                        :on-error="imageUploadError"
                                        :on-remove="imageRemove"
                                    >
                                        <el-icon><Plus /></el-icon>
                                    </el-upload>

                                    <span>按 Ctrl+V 粘贴上传，或者点击批量上传（只保存第一张）</span>
                                </div>

                                <el-table
                                    :data="addForm.table" 
                                    border style="width: 100%"
                                >
                                    <el-table-column prop="url" label="图片链接" width="200" align="center">
                                        <template v-slot="scope">
                                            <a :href=scope.row.url target="_blank" class="ellipsis">{{ scope.row.url }}</a>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="name" label="名称" width="200" align="center"/>
                                    <el-table-column prop="remarks" label="备注" align="center">
                                        <template v-slot="scope">
                                        <el-input
                                            v-model="scope.row.remarks"
                                            placeholder="请输入内容"
                                        />
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-form-item>

                            <el-form-item
                                v-if="addForm.type == 2"
                                label="链接"
                                required
                            >
                                <el-input
                                    v-model="addForm.url"
                                    :rows="2"
                                    type="textarea"
                                    placeholder=""
                                />
                            </el-form-item>

                            <el-form-item
                                v-if="addForm.type == 2"
                                label="备注"
                            >
                                <el-input
                                    v-model="addForm.remarks"
                                    placeholder=""
                                />
                            </el-form-item>

                        </el-form>

                        <template #footer>
                            <div class="dialog-footer">
                                <el-button @click="addCancel">取消</el-button>
                                <el-button type="primary" @click="addSubmit">保存</el-button>
                            </div>
                        </template>
                    </el-dialog>
                </div>

                
            </el-col>
        </el-row>

        <el-row>
            <el-col>
                <template v-if="data">
                    <el-image
                        style="width: 100%; max-width: 300px;"
                        :src="data.url"
                        alt="Image"
                    />
                </template>
                <el-descriptions :column="1" v-if="data">
                    <el-descriptions-item label="链接:">{{ data.url }}</el-descriptions-item>
                    <el-descriptions-item label="备注:">{{ data.remarks }}</el-descriptions-item>
                    <el-descriptions-item label="时间:">{{ data.create_time }}</el-descriptions-item>
                </el-descriptions>
            </el-col>
        </el-row>

    </div>
</template>

<script>
import { apis } from '../apis';

export default {
    data() {
        return {
            data: null,
            dialogAddVisible: false,
            addForm: {type: '1'},
            fileList: [],
        };
    },
    mounted() {
        this.fetchData();
        document.addEventListener('paste', this.imagesPaste);
    },
    unmounted() {
        document.removeEventListener('paste', this.imagesPaste);
    },
    watch: {
        fileList: { 
            handler(newVal, oldVal) {
                this.fileListChange(newVal, oldVal);
            },
            deep: true,
        },
    },
    computed: {},
    methods: {
        addSubmit() {
            let data = {};
            if (this.addForm.type == '1') {
                if (!this.addForm.table || this.addForm.table.length == 0) {
                    this.$message.error('请上传图片');
                    return
                }
                for (let i = 0; i < this.addForm.table.length; i++) {
                    let elem = this.addForm.table[i];
                    data = {
                        url: elem.url,
                        remarks: elem.remarks,
                    };
                    break;
                }
            }
            if (this.addForm.type == '2') {
                if (!this.addForm.url) {
                    this.$message.error('请填写链接');
                    return
                }
                data = {
                    url: this.addForm.url,
                    remarks: this.addForm.remarks,
                };
            }

            apis.post('/api/v2/subcodes', data).then(() => {
                this.fetchData();
                this.$message.success('更新成功');
                this.addCancel();
            }).catch(() => {});
        },
        addCancel() {
            this.addForm = {type: '1'};
            this.fileList = [];
            this.dialogAddVisible = false;
        },
        fileListChange(newVal) {
            this.addForm.table = []
            for (let i = 0; i < newVal.length; i++) {
                let elem = newVal[i];
                this.addForm.table.push({
                    url: elem.response.data.url,
                    name: elem.name,
                    remarks: elem.name,
                });
            }
        },
        imagesPaste(event) {
            const items = event.clipboardData.items;
            for (let i = 0; i < items.length; i++) {
                if (items[i].kind === 'file') {
                    const file = items[i].getAsFile();
                    this.addFileToUpload(file);
                }
            }
        },
        addFileToUpload(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const imgBase64 = e.target.result;
                const newFile = new File([file], file.name, { type: file.type });
                const uploadFile = {
                    name: file.name,
                    url: imgBase64,
                    raw: newFile
                };
                // this.fileList.push(uploadFile);
                this.$nextTick(() => {
                    this.uploadRequest({
                        filename: 'file',
                        action: 'https://china2.xlzy520.cn/xd/upload',
                        file: uploadFile.raw,
                        addFile: uploadFile,
                        onSuccess: this.imageUploadSuccess,
                        onError: this.imageUploadError,
                    });
                    // this.$refs.upload.submit(); // 触发上传，唉，搞了好久都不好使，曲线救国了。。。
                });
            };
            reader.readAsDataURL(file);
        },
        uploadRequest(option) {
            const formData = new FormData();
            formData.append(option.filename, option.file);

            fetch(option.action, {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(response => {
                if (response.success && response.data && response.data.url) {
                    option.onSuccess(response, option.file, this.fileList, option.addFile);
                } else {
                    const errorMessage = response.message || 'Upload failed';
                    option.onError(new Error(errorMessage));
                }
            })
            .catch(error => {
                option.onError(error);
            });
        },
        imageUploadSuccess(response, file, fileList, addFile) {
            this.fileList = fileList;

            // CV复制上传成功，再添加文件
            if (addFile) {
                addFile.response = response
                this.fileList.push(addFile);
            }
        },
        imageUploadError(err, file, fileList) {
            this.$message.error(`${file.name} 上传失败 ${err}`);
            this.fileList = fileList;
        },
        imageRemove(file, fileList) {
            this.fileList = fileList;
        },
        fetchData() {
            apis.get('/api/v2/subcodes').then(resp => {
                this.data = resp.data;
            }).catch(() => {});
        },
    },
};
</script>

<style scoped>
.box-card {
  margin: 20px;
}
.button-col {
  text-align: center;
  margin-top: 20px;
}

::v-deep .center-label {
  display: flex;
  align-items: center;
  height: 100%;
}

</style>
