<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div>
        <el-row>
            <el-col>
                <div>
                    <el-breadcrumb :separator-icon="ArrowRight">
                        <el-breadcrumb-item :to="{ path: '/sly' }">网推码</el-breadcrumb-item>
                        <el-breadcrumb-item>主码管理</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </el-col>
        </el-row>
        
        <el-row>
            <el-col :span="20">
                <div>
                    <el-button type="primary" :icon="Plus" @click="dialogAddVisible = true" style="margin-right: 100px;">添加链接</el-button>
                    <el-dialog
                        v-model="dialogAddVisible"
                        title="添加"
                        :close-on-click-modal="false"
                        width="70%"
                        style="padding-right: 50px; padding-bottom: 30px;"
                    >

                        <el-form
                            :model="addForm"
                            label-position="right"
                            label-width="110"
                        >

                            <el-form-item label="方式">
                                <el-radio-group v-model="addForm.type" class="ml-4">
                                    <el-radio value="1" size="large">上传图片，自动获取图片链接</el-radio>
                                    <el-radio value="2" size="large">手动输入链接</el-radio>
                                </el-radio-group>
                            </el-form-item>

                            <el-form-item label="目标次数" required>
                                <el-input-number v-model="addForm.target" :step="20" min="0"/>
                            </el-form-item>

                            <el-form-item
                                v-if="addForm.type == 1"
                                label="图片上传"
                                required
                            >
                                <div>
                                    <el-upload
                                        ref="upload"
                                        action="https://china2.xlzy520.cn/xd/upload"
                                        :file-list="fileList"
                                        list-type="picture-card"
                                        :auto-upload="true"
                                        :on-success="imageUploadSuccess"
                                        :on-error="imageUploadError"
                                        :on-remove="imageRemove"
                                    >
                                        <el-icon><Plus /></el-icon>
                                    </el-upload>

                                    <span>按 Ctrl+V 粘贴上传，或者点击批量上传</span>
                                </div>

                                <el-table
                                    :data="addForm.table" 
                                    border style="width: 100%"
                                >
                                    <el-table-column prop="url" label="图片链接" width="200" align="center">
                                        <template v-slot="scope">
                                            <a :href=scope.row.url target="_blank" class="ellipsis">{{ scope.row.url }}</a>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="name" label="名称" width="200" align="center"/>
                                    <el-table-column prop="remarks" label="备注" align="center">
                                        <template v-slot="scope">
                                        <el-input
                                            v-model="scope.row.remarks"
                                            placeholder="请输入内容"
                                        />
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-form-item>

                            <el-form-item
                                v-if="addForm.type == 2"
                                label="链接"
                                required
                            >
                                <el-input
                                    v-model="addForm.url"
                                    :rows="2"
                                    type="textarea"
                                    placeholder=""
                                />
                            </el-form-item>

                            <el-form-item
                                v-if="addForm.type == 2"
                                label="备注"
                            >
                                <el-input
                                    v-model="addForm.remarks"
                                    placeholder=""
                                />
                            </el-form-item>

                        </el-form>

                        <template #footer>
                            <div class="dialog-footer">
                                <el-button @click="addCancel">取消</el-button>
                                <el-button type="primary" @click="addSubmit">保存</el-button>
                            </div>
                        </template>
                    </el-dialog>

                    <el-input
                        v-model="remarks"
                        style="width: 400px; margin-right: 12px;"
                        placeholder="检索备注"
                        >
                        <template #prepend>
                            <el-select v-model="status" clearable placeholder="选择状态" style="width: 115px">
                            <el-option label="执行中" value="1" />
                            <el-option label="待执行" value="2" />
                            <el-option label="暂停" value="3" />
                            <el-option label="完成" value="4" />
                            </el-select>
                        </template>
                        <template #append>
                            <el-button :icon="Search" @click="getTableData"/>
                        </template>
                    </el-input>

                    <!-- <el-input v-model="input" style="width: 200px; margin-right: 12px;" placeholder="检索备注" />
                    <el-button type="primary" :icon="Search">搜索</el-button> -->
                    <el-button type="danger" :disabled="batchDelList.length == 0" @click="batchDelete">批量删除</el-button>

                </div>
            </el-col>

            <el-col :span="4">
                <!-- <el-link v-if="ic" type="success">访问IP：今天[{{ ic.today }}]、昨天[{{ ic.yesterday }}]</el-link> -->
                <!-- <el-tag type="success">Tag 2</el-tag>
                <el-tag type="info">Tag 3</el-tag> -->
                <div>
                    <template v-if="ic">
                        <!-- <el-link type="success">访问IP：今天[{{ ic.today }}] 昨天[{{ ic.yesterday }}]</el-link> -->
                        <el-text class="mx-1" size="large">访问IP：今天 </el-text>
                        <el-text class="mx-1" type="success" size="large">{{ ic.today }}</el-text>
                        <el-text class="mx-1" size="large">、昨天 </el-text>
                        <el-text class="mx-1" type="warning" size="large">{{ ic.yesterday }}</el-text>
                    </template>
                </div>
            </el-col>
        </el-row>
        

        <el-row>
            <el-col>
                <div>
                    <el-table
                        v-loading="loading"
                        :data="tableData" 
                        border style="width: 100%"
                        @selection-change="handleSelectionChange"
                    >
                        <el-table-column type="selection" width="50" align="center"/>
                        <el-table-column prop="id" label="ID" width="70" align="center"/>
                        <el-table-column prop="url" label="预览" width="120" align="center">
                            <template v-slot="scope">
                                <el-image
                                    :src="scope.row.url"
                                    fit="cover"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column prop="url" label="链接" width="300">
                            <template v-slot="scope">
                                <div style="font-size: 14px;">
                                    {{ scope.row.url }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="create_time" label="次数" width="120" align="center">
                            <template v-slot="scope">
                                <span>{{scope.row.count}} / {{ scope.row.target }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="status" label="状态" width="100" align="center">
                            <template v-slot="scope">
                                <el-tag :type="getStatusTagType(scope.row.status)">
                                    {{ getStatusTagText(scope.row.status) }}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="create_time" label="创建时间" width="155" align="center"/>
                        <el-table-column prop="complete" label="完成时间" width="155" align="center"/>
                        <el-table-column prop="remarks" label="备注" align="center"/>
                        <el-table-column label="操作" align="center" fixed="right" width="190">
                            <template #default="{ row }">
                                <div class="button-row">
                                    <el-button size="small" @click="handleEdit(row)">编辑</el-button>
                                    <el-button size="small" @click="handleAccess(row)">日志</el-button>
                                    <el-button size="small" type="danger" @click="handleDel(row)">删除</el-button>
                                </div>
                                <div class="button-row">
                                    <el-button size="small" @click="handleExec(row)" :disabled="row.complete">执行</el-button>
                                    <el-button size="small" @click="handlePause(row)"  :disabled="row.complete">暂停</el-button>
                                    <el-button size="small" @click="handleComplete(row)"  :disabled="row.complete">完成</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div>
                        <el-dialog
                            v-model="dialogEditVisible"
                            title="修改"
                            :close-on-click-modal="false"
                            width="70%"
                            style="padding-right: 50px; padding-bottom: 30px;"
                        >

                            <el-form
                                :model="editFrom"
                                label-position="right"
                                label-width="110"
                            >

                                <el-form-item label="目标次数" required>
                                    <el-input-number v-model="editFrom.target" :step="20" min="0"/>
                                </el-form-item>

                                <el-form-item
                                    label="链接"
                                    required
                                >
                                    <el-input
                                        v-model="editFrom.url"
                                        :rows="2"
                                        type="textarea"
                                        placeholder=""
                                    />
                                </el-form-item>

                                <el-form-item
                                    label="备注"
                                >
                                    <el-input
                                        v-model="editFrom.remarks"
                                        placeholder=""
                                    />
                                </el-form-item>

                            </el-form>

                            <template #footer>
                                <div class="dialog-footer">
                                    <el-button @click="dialogEditVisible = false">取消</el-button>
                                    <el-button type="primary" @click="editSubmit">更新</el-button>
                                </div>
                            </template>
                        </el-dialog>
                    </div>

                    <div>
                        <el-dialog
                            v-model="dialogAccessVisible"
                            title="访问日志"
                            :close-on-click-modal="false"
                            :close="dialogAccessClose"
                            width="1115"
                            style="padding-left: 30px; padding-right: 30px; padding-bottom: 30px;"
                        >

                            <el-table
                                v-loading="accessLoading"
                                :data="accessTableData" 
                                border
                            >
                                <el-table-column prop="id" label="ID" width="70" align="center"/>
                                <el-table-column prop="time" label="访问时间" width="155" align="center"/>
                                <el-table-column prop="ip" label="IP" width="160" align="center"/>
                                <el-table-column prop="ua" label="UA" width="670" align="center"/>
                            </el-table>

                            <el-pagination
                                v-model:current-page="accessCurrentPage"
                                v-model:page-size="accessPageSize"
                                :page-sizes="[10, 20, 30, 40, 50]"
                                background="false"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="accessTotal"
                                @size-change="handleAccessSizeChange"
                                @current-change="handleAccessCurrentChange"
                            />
                        </el-dialog>
                    </div>

                </div>
            </el-col>
        </el-row>

        <el-row>
            <el-col>
                <el-pagination
                    v-model:current-page="currentPage"
                    v-model:page-size="pageSize"
                    :page-sizes="[10, 20, 30, 40, 50]"
                    background="false"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </el-col>
        </el-row>


    </div>
</template>

<script>
import { apis } from '../apis';
import { Plus, Search } from '@element-plus/icons-vue'
import { ElMessageBox } from 'element-plus'

export default {
    data() {
        return {
            Plus,
            Search,
            loading: true,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            intervalId: 0,
            tableData: [],
            dialogAddVisible: false,
            dialogEditVisible: false,
            dialogAccessVisible: false,
            addForm: {type: '1', target: 0},
            editFrom: {},
            fileList: [],
            accessCID: 0,
            accessLoading: true,
            accessTableData: [],
            accessTotal: 0,
            accessCurrentPage: 1,
            accessPageSize: 10,
            status: '',
            remarks: '',
            batchDelList: [],
            ic: null,
        };
    },
    mounted() {
        this.init();
        this.intervalId = setInterval(() => { this.init(); }, 20000);
        document.addEventListener('paste', this.imagesPaste);
    },
    unmounted() {
        // 卸载页面时停止，否则会一直执行
        clearInterval(this.intervalId);
        document.removeEventListener('paste', this.imagesPaste);
    },
    watch: {
        fileList: { 
            handler(newVal, oldVal) {
                this.fileListChange(newVal, oldVal);
            },
            deep: true,
        },
    },
    computed: {},
    methods: {
        batchDelete() {
            if (this.batchDelList.length == 0) {
                return;
            }
            let data = [];
            for (let i = 0; i < this.batchDelList.length; i++) {
                let elem = this.batchDelList[i];
                data.push(elem.id);
            }
            apis.put(`/api/v2/codes/batch/delete`, data).then(() => {
                this.getTableData();
                this.batchDelList = [];
                this.$message.success('批量删除成功');
            }).catch(() => {});
        },
        handleSelectionChange(selection) {
            this.batchDelList = selection;
        },
        dialogAccessClose() {
            this.accessCID = 0;
            this.accessTableData = [];
            this.accessTotal = 0;
            this.accessLoading = true;
        },
        handleAccessSizeChange() {
            this.getAccessTableData();
        },
        handleAccessCurrentChange() {
            this.getAccessTableData();
        },
        handleAccess(row) {
            this.accessCID = row.id;
            this.dialogAccessVisible = true;
            this.getAccessTableData();
        },
        getAccessTableData() {
            apis.get(`/api/v2/codes/access/${this.accessCID}?page=${this.accessCurrentPage}&perPage=${this.accessPageSize}`).then(resp => {
                this.accessTableData = resp.data.rows;
                this.accessTotal = resp.data.count;
                this.accessLoading = false;
            }).catch(() => {});
        },
        handleDel(row) {
            ElMessageBox.confirm(
                '确定要删除吗？',
                '信息',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
            .then(() => {
                apis.put(`/api/v2/codes/${row.id}/delete`).then(() => {
                    this.getTableData();
                    this.$message.success('完成成功');
                }).catch(() => {});
            }).catch(() => {})
        },
        handleComplete(row) {
            ElMessageBox.confirm(
                '确定要完成吗？',
                '信息',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
            .then(() => {
                apis.put(`/api/v2/codes/${row.id}/complete`).then(() => {
                    this.getTableData();
                    this.$message.success('完成成功');
                }).catch(() => {});
            }).catch(() => {})
        },
        handlePause(row) {
            ElMessageBox.confirm(
                '确定要暂停吗？',
                '信息',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
            .then(() => {
                apis.put(`/api/v2/codes/${row.id}/pause`).then(() => {
                    this.getTableData();
                    this.$message.success('暂停成功');
                }).catch(() => {});
            }).catch(() => {})
        },
        handleExec(row) {
            ElMessageBox.confirm(
                '确定要执行吗？',
                '信息',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
            .then(() => {
                apis.put(`/api/v2/codes/${row.id}/doing`).then(() => {
                    this.getTableData();
                    this.$message.success('执行成功');
                }).catch(() => {});
            }).catch(() => {})
        },
        editSubmit() {
            let data = {
                url: this.editFrom.url,
                target: this.editFrom.target,
                remarks: this.editFrom.remarks,
            };
            if (data.target <= 0) {
                this.$message.error('目标次数不能为空');
                return
            }
            if (!data.url) {
                this.$message.error('链接不能为空');
                return
            }

            apis.put(`/api/v2/codes/${this.editFrom.id}`, data).then(() => {
                this.getTableData();
                this.$message.success('更新成功');
                this.dialogEditVisible = false
            }).catch(() => {});
        },
        handleEdit(row) {
            this.editFrom = row;
            this.dialogEditVisible = true;
        },
        handleSizeChange() {
            this.getTableData();
        },
        handleCurrentChange() {
            this.getTableData();
        },
        addSubmit() {
            if (this.addForm.target <= 0) {
                this.$message.error('请设置目标次数');
                return
            }

            let data = [];
            if (this.addForm.type == '1') {
                if (!this.addForm.table || this.addForm.table.length == 0) {
                    this.$message.error('请上传图片');
                    return
                }
                for (let i = 0; i < this.addForm.table.length; i++) {
                    let elem = this.addForm.table[i];
                    data.push({
                        url: elem.url,
                        remarks: elem.remarks,
                        target: this.addForm.target,
                    });
                }
            }
            if (this.addForm.type == '2') {
                if (!this.addForm.url) {
                    this.$message.error('请填写链接');
                    return
                }
                data.push({
                    url: this.addForm.url,
                    remarks: this.addForm.remarks,
                    target: this.addForm.target,
                });
            }

            apis.post('/api/v2/codes', data).then(() => {
                this.getTableData();
                this.$message.success('添加成功');
                this.addCancel();
            }).catch(() => {});
        },
        addCancel() {
            this.addForm = {type: '1', target: 0};
            this.fileList = [];
            this.dialogAddVisible = false;
        },
        fileListChange(newVal) {
            this.addForm.table = []
            for (let i = 0; i < newVal.length; i++) {
                let elem = newVal[i];
                this.addForm.table.push({
                    url: elem.response.data.url,
                    name: elem.name,
                    remarks: elem.name,
                });
            }
        },
        imagesPaste(event) {
            const items = event.clipboardData.items;
            for (let i = 0; i < items.length; i++) {
                if (items[i].kind === 'file') {
                    const file = items[i].getAsFile();
                    this.addFileToUpload(file);
                }
            }
        },
        addFileToUpload(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const imgBase64 = e.target.result;
                const newFile = new File([file], file.name, { type: file.type });
                const uploadFile = {
                    name: file.name,
                    url: imgBase64,
                    raw: newFile
                };
                // this.fileList.push(uploadFile);
                this.$nextTick(() => {
                    this.uploadRequest({
                        filename: 'file',
                        action: 'https://china2.xlzy520.cn/xd/upload',
                        file: uploadFile.raw,
                        addFile: uploadFile,
                        onSuccess: this.imageUploadSuccess,
                        onError: this.imageUploadError,
                    });
                    // this.$refs.upload.submit(); // 触发上传，唉，搞了好久都不好使，曲线救国了。。。
                });
            };
            reader.readAsDataURL(file);
        },
        uploadRequest(option) {
            const formData = new FormData();
            formData.append(option.filename, option.file);

            fetch(option.action, {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(response => {
                if (response.success && response.data && response.data.url) {
                    option.onSuccess(response, option.file, this.fileList, option.addFile);
                } else {
                    const errorMessage = response.message || 'Upload failed';
                    option.onError(new Error(errorMessage));
                }
            })
            .catch(error => {
                option.onError(error);
            });
        },
        imageUploadSuccess(response, file, fileList, addFile) {
            this.fileList = fileList;

            // CV复制上传成功，再添加文件
            if (addFile) {
                addFile.response = response
                this.fileList.push(addFile);
            }
        },
        imageUploadError(err, file, fileList) {
            this.$message.error(`${file.name} 上传失败 ${err}`);
            this.fileList = fileList;
        },
        imageRemove(file, fileList) {
            this.fileList = fileList;
        },
        init() {
            this.getTableData();
            this.getIPCount();
        },
        getTableData() {
            let url = `/api/v2/codes?page=${this.currentPage}&perPage=${this.pageSize}`;
            if (this.status) {
                url += `&status=${this.status}`;
            }
            if (this.remarks) {
                url += `&remarks=${this.remarks}`;
            }
            apis.get(url).then(resp => {
                this.tableData = resp.data.rows;
                this.total = resp.data.count;
                this.loading = false;
            }).catch(() => {});
        },
        getIPCount() {
            apis.get(`/api/v2/ic/current`).then(resp => {
                this.ic = resp.data;
            }).catch(() => {});
        },
        getStatusTagType(status) {
            switch(status) {
                case 1:
                    return 'primary';
                case 2:
                    return 'info';
                case 3:
                    return 'warning';
                case 4:
                    return 'success';
                default:
                    return '';
            }
        },
        getStatusTagText(status) {
            switch(status) {
                case 1:
                    return '执行中';
                case 2:
                    return '待执行';
                case 3:
                    return '暂停';
                case 4:
                    return '完成';
                default:
                    return '未知状态';
            }
        },
    },
};
</script>

<style>
.el-row {
    margin-bottom: 20px;
}
.el-row:last-child {
    margin-bottom: 0;
}

.ellipsis {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button-row {
  display: flex;
  margin-bottom: 5px; /* 调整行间距 */
}

</style>
