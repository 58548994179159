import { createRouter, createWebHistory } from "vue-router";
import Info from "./views/Info.vue";
import Codes from "./views/Codes.vue";
import SubCode from "./views/SubCode.vue";
import Website from "./views/Website.vue";
import WebsiteTpl from "./views/WebsiteTpl.vue";
import WebsiteHtml from "./views/WebsiteHtml.vue";
import HomePage from "./views/HomePage.vue";
import DyInfo from "./views/DyInfo.vue";
import DyCodes from "./views/DyCodes.vue";
import DySubCode from "./views/DySubCode.vue";


const routes = [
    // 首页
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: "/home",
        component: HomePage
    },
    // 网页管理
    {
        path: "/slysite",
        redirect: '/sly/website',
    },
    {
        path: "/slysite/websiteTpl",
        component: WebsiteTpl
    },
    {
        path: "/slysite/website",
        component: Website
    },
    {
        path: "/slysite/websitehtml",
        component: WebsiteHtml
    },
    // 内推码
    {
        path: '/sly',
        redirect: '/sly/codes',
    },
    {
        path: "/sly/info",
        component: Info
    },
    {
        path: "/sly/codes",
        component: Codes
    },
    {
        path: "/sly/subcode",
        component: SubCode
    },
    // 抖音码
    {
        path: '/dy',
        redirect: '/dy/codes',
    },
    {
        path: "/dy/info",
        component: DyInfo,
    },
    {
        path: "/dy/codes",
        component: DyCodes
    },
    {
        path: "/dy/subcode",
        component: DySubCode
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
