<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
        <el-row>
            <el-col>
                <div>
                    <el-breadcrumb :separator-icon="ArrowRight">
                        <el-breadcrumb-item :to="{ path: '/sly' }">网推码</el-breadcrumb-item>
                        <el-breadcrumb-item>信息</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </el-col>
        </el-row>

        <el-row>
            <el-col>
                <el-card shadow="hover">
                    <div>
                        <el-descriptions :column="1" v-if="user" title="账户信息">
                            <el-descriptions-item label="账号名称:">
                                {{ user.username }}
                            </el-descriptions-item>
                            <el-descriptions-item label="到期时间:">
                                {{ user.expireAt }}
                            </el-descriptions-item>
                            <el-descriptions-item label="主码链接:">
                                <a :href="getOpenURL()" target="_blank">{{ getOpenURL() }}</a>
                            </el-descriptions-item>
                            <el-descriptions-item label="副码链接:">
                                <a :href="getOpenSubURL()" target="_blank">{{ getOpenSubURL() }}</a>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <el-row>
            <el-col>
                <el-card shadow="hover" style="width: 100%; height: 430px;">
                    <div ref="chart" style="width: 100%; height: 430px;"></div>
                </el-card>
            </el-col>
        </el-row>

    </div>
</template>

<script>
import { apis } from '../apis';
import { mapGetters, mapActions } from 'vuex';
import * as echarts from 'echarts';

export default {
    data() {
        return {
            activeIndex: 2,
            chartData: [],
            chart: null,
            intervalId: null,
        };
    },
    async mounted() {
        await this.fetchData();
        this.initChart();
        this.startAutoUpdate();
    },
    unmounted() {
        clearInterval(this.intervalId);
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'getUser']),
        user() {
            return this.getUser;
        }
    },
    methods: {
        ...mapActions(['logout']),
        init() {
            this.initChart();
            this.getchartData();
            console.log(this.user);
        },
        getCurrentDateFormatted() {
            let date = new Date();
            let year = date.getFullYear();
            let month = String(date.getMonth() + 1).padStart(2, '0');
            let day = String(date.getDate()).padStart(2, '0');
            return `${year}${month}${day}`;
        },
        async fetchData() {
            let ext = this.user.ext;
            if (!ext) {
                return;
            }
            this.chartData = [];
            for (let i = 0; i < ext.ic.length; i++) {
                let elem = ext.ic[i];
                this.chartData.push({
                    name: elem.d,
                    value: elem.c,
                });
            }
            
            apis.get(`/api/v2/ic/current`).then(resp => {
                this.chartData.push({
                    name: this.getCurrentDateFormatted(),
                    value: resp.data.today,
                });
                this.updateChart();
            }).catch(() => {});
        },
        initChart() {
            this.chart = echarts.init(this.$refs.chart);
            this.updateChart();
        },
        updateChart() {
            const option = {
                title: {
                    text: '最近30天访问IP次数'
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: '{b}: {c}'
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.chartData.map(item => item.name)
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    name: '数据',
                    type: 'line',
                    smooth: true,
                    data: this.chartData.map(item => item.value),
                    itemStyle: {
                        normal: {
                            label: {
                                // show: false
                                show: true,  // 显示数据标签
                                formatter: '{c}' // 数据标签的格式
                            }
                        }
                    }
                }]
            };
            this.chart.setOption(option);
        },
        startAutoUpdate() {
            this.intervalId = setInterval(() => {
                this.fetchData();
            }, 20000); // 每20秒更新一次数据
        },
        getOpenURL() {
            let url = '';
            url = `${window.location.protocol}//${window.location.host}/api/open/v2/codes/${this.user.token}/${this.user.uid}`;
            return url
        },
        getOpenSubURL() {
            let url = '';
            url = `${window.location.protocol}//${window.location.host}/api/open/v2/subcodes/${this.user.token}/${this.user.uid}`;
            return url
        },
    }
};
</script>

<style scoped>
.card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 700px;
  height: 50px; /* 设置卡片高度，根据需要调整 */
}

.content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.descriptions {
  width: 100%;
}

.descriptions-item {
  display: flex;
  align-items: center;
}

</style>
