<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="header-container">
        <div class="header-logo">
            <span class="header-title">网推义父</span>
        </div>


        <div>
            <el-menu
                default-active="1"
                mode="horizontal"
                @select="handleSelect"
                class="header-user"
            >
                <el-sub-menu index="1" class="custom-sub-menu">
                    <template #title>hi, {{ user.username }}</template>
                    <el-menu-item index="logout">退出登录</el-menu-item>
                </el-sub-menu>
            </el-menu>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Cookies from 'js-cookie';

export default {
    data() {
        return {
            activeIndex: 2,
        };
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'getUser']),
        user() {
            return this.getUser;
        }
    },
    methods: {
        ...mapActions(['logout']),
        handleSelect(index) {
            if (index == 'logout') {
                Cookies.remove('Admin_Token');
                location.reload();
            }
       },
    }
};
</script>

<style scoped>

.header-container {
    height: 60px;
    left: 0;
    top: 0;
    width: 100%;
    line-height: 60px;
    /* background-color: #545c64; */
}

.header-logo {
    width: 200px;
    float: left;
}

.header-title {
    color: #000;
    text-shadow: none;
    display: block;
    height: 60px;
    line-height: 60px;
    text-decoration: none;
    font-size: 25px;
    text-align: center;
}

.header-user {
    float: right;
    width: 180px;

}

</style>